(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-list.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/draw-selector-list.js');

'use strict';

const {
  DrawCardContainer
} = svs.components.tipsen.drawCard;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const DrawSelectorList = _ref => {
  let {
    branding,
    draws = [],
    onToggleFunc,
    selectedDrawNumber
  } = _ref;
  const drawSelectorClassNames = ['pg_draw_selector', 'pg_draw_selector_list'];
  branding && drawSelectorClassNames.push("pg_draw_selector--".concat(branding));
  return React.createElement("div", {
    className: drawSelectorClassNames.join(' ')
  }, draws.map(draw => {
    const isSelected = draw.drawNumber === selectedDrawNumber;
    return React.createElement(DrawCardContainer, {
      branding: branding,
      currentDraw: draw,
      drawCardVariant: DRAW_CARD_VARIANTS.SMALL,
      isListItem: true,
      isSelected: isSelected,
      key: draw.drawNumber,
      onChangeDraw: onToggleFunc
    });
  }));
};
setGlobal('svs.components.tipsen.drawSelector.DrawSelectorList', DrawSelectorList);

 })(window);