(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-selector-2023/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-selector-2023/assets/javascripts/constants.js');
"use strict";


const {
  DRAW_CARD_TITLES
} = svs.components.tipsen.drawCard.constants;
const DRAW_SORT_FLOW = {
  REG_CLOSE_TIME: {
    value: 'regCloseTime',
    title: DRAW_CARD_TITLES.BET_STOP
  },
  NET_SALE: {
    value: 'netSale',
    title: DRAW_CARD_TITLES.NET_SALE
  },
  EXTRA_MONEY: {
    value: 'extraMoney',
    title: DRAW_CARD_TITLES.EXTRA_MONEY
  }
};
const DRAW_SORT_QS = 'sortDrawsBy';
const DRAW_FILTER_QS = 'drawsBySport';
setGlobal('svs.components.tipsen.drawSelector.constants', {
  DRAW_SORT_FLOW,
  DRAW_SORT_QS,
  DRAW_FILTER_QS
});

 })(window);